.card-component-wrapper{
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:5px;
}
.icon-wrapper{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;
   
}

.card-component-wrapper>h2{
   font-family: "Rowdies";
}

.card-component-wrapper>p{
    color: var(--textColor1);
    text-align: center;
}


