.Section{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-container{
  display: flex;
  justify-content: space-between;
  width: 80%;
  gap:60px;
  padding: 6rem 0;
}
.section-container>div{
  width: 50%;
  display: flex;

  align-items: center;
  flex-direction: column;
}
 


.user-details{

  display: flex;
  flex-direction: column;
  gap:15px;
}


.confrim-buttons{
  display: flex;
  flex-direction: column;
  text-align: center;
  gap:10px;
}


.genedercontainer{
    
    display: flex;
    justify-content: space-between;
    width: 30%;

}







 @media screen and (max-width:750px) {
 
  .section-container{
   flex-direction: column;
   padding: 4rem 0;
  }
  .section-container>div{
   width: 100%;
  }

  .genedercontainer{
    
    width: 50%;
    margin: auto;

}

 

}
