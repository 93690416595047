.DashboardHomeCard{
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background-color: white;
    padding:16px;
}
.DashboardHomeCard>.left{
    width: 60%;
}
.DashboardHomeCard>.right{
    width: 40%;
    display: flex;
    justify-content: right;
    align-items: center;
}

.icon-container{
    width:50px;
    height: 50px;
    border-radius: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: white;
    background-color: #556EE6;
}

@media screen and (max-width: 768px) {
    .DashboardHomeCard>.left>p{
       font-size: 14px;
    }
    .icon-container{
        width:20px;
        height: 20px;
        border-radius: 50%;
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: white;
        background-color: #556EE6;
    }
}