.marquee-container {
    width: 100%;
    overflow: hidden;
    background-color: #f5f5f5; /* Optional background color */
    padding: 10px;
  }
  
  .marqueeicon {
    font-size: 50px; /* Adjust the size of the icons */
    margin: 0 20px; /* Spacing between icons */
    color: #0070ba; /* Adjust color, this is PayPal blue as an example */
  }
  .marqueeImage {
    width: 80px;
    display: inline-flex;
    margin: 0 25px; 
  }