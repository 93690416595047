.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.input {
  padding: 10px;
  margin: 10px 0;
  width: 100%;
}

.error {
  color: red;
}

.submit-btn,
.close-btn {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}
