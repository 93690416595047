/* MyCollapsible.css */

.custom-trigger {
   
    background: rgb(81, 152, 210);
    border: none;
    cursor: pointer;
    padding: 10px;
    text-align: left;
  }
  
  .custom-trigger:hover {
    background: rgb(44, 109, 162);
  }
  
  .custom-trigger-open {
    color: red;
  }
  
  .custom-content-outer {
    border: 1px solid rgb(200, 219, 235);
    border-top: none;
  }
  
  .custom-content-inner {
    padding: 10px;
  }
  
  .custom-content {
    color: var(--textColor1);
  }

  .FAQs-Main{
    text-align: center;
  }

.FAQ-List{
    display: flex;
    text-align: left;
}
.FAQ-List>div{
    width:50%;
    padding:15px;
    display: flex;
    flex-direction: column;
    gap:10px;
}

@media screen and (max-width: 768px) {
    .FAQ-List{
        flex-direction: column;
        gap:10px
    }
    .FAQ-List>div{
        width: 100%;
       padding: 5px;

    }
}
  