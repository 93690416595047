.hero-container{
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 0 auto;
  
}
.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
   
    width: 100%; 
    height: 89%; 
    background-color: rgba(201, 220, 249, 0.5);
    z-index: -1; 
  }
.hero-container>div{
   width:50%;
  
}

.hero-left{
   padding:30px;
}
.hero-right>img{
    width: 80%;
    height: auto;
    margin:0 auto;
}

.herowebname{
    font-family: 'Rowdies';
    font-size: 25px;
}

.button-div{
    background-color: #007CC5;
    border-radius: 5px;
    padding:10px;
    color:white;
}
.hero-button-wrapper{
    display: flex;
    gap:10px;
}

.service-list>li{
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: 'Rowdies';
        font-size: 25px;
}
.service-list>li>i{
       color:#007CC5;
}
.sider-content{
    display: flex;
    flex-direction: column;
    gap:20px;
    font-size: 20px;
    padding: 10px;
   
}
.sider-content>h1{
    font-family: 'Rowdies';
        font-size: 35px;
        color: #007CC5;
        text-align: left;
   
}
.sider-content>p{
   
        font-size: 20px;
   
}

@media screen and (max-width: 768px) {
    .hero-container{
        flex-direction: column;
        width: 100%;
    }
   
    .hero-container>div{
        width:100%; 
     }
     .service-list>li{
        font-size: 1.2rem; 
}
.sider-content{
    font-size: 1rem;
}

.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }

  .sider-content>h1{
        font-size: 20px;
        text-align: center;
}
.sider-content>p{
        font-size: 20px;
       
}
.herowebname{
    font-family: 'Rowdies';
    font-size: 20px;
}
}